import React, {Component} from "react";
import nubelson from "../images/nubelson.jpg";

export default class About extends Component {
    render() {
        return (
            <section className="about-us section-padding" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 valign md-mb50">
                            <div className="mb-50">
                                <h6 className="fw-100 text-u ls10 mb-10">About Us</h6>
                                <h3>Our Company</h3>
                                <p>Stackgenio is a HubSpot Certified Partner Agency that can help you navigate your
                                    HubSpot journey to scale your business.</p>
                                <p>&nbsp;</p>
                                <p>The Stackgenio team provides end to end HubSpot and Web App Development solutions for
                                    your team so you can focus on what matters most - building long lasting customer
                                    relationships and growing revenue.</p>
                                <p>&nbsp;</p>
                                <p>We are a small team of Inbound Marketing Experts and HubSpot Certified Partner that
                                    can set up your Inbound Marketing Strategy to scale your business. We will work with
                                    you to set up systems that work best for your business. That means smart,
                                    data-oriented strategies that combine inbound marketing, sales acceleration, and
                                    ongoing optimization to grow revenue and marketing ROI.</p>
                                <p>&nbsp;</p>
                                <p>
                                    Be it Sales Acceleration, HubSpot CRM set up, content scheduling, or ongoing
                                    optimization and technical HubSpot support – the HubSpot certified Stackgenio team
                                    is always ready to help. Not just HubSpot services, we also design and develop custom
                                    modern websites and web applications to meet all your business needs. </p>
                                <p>&nbsp;</p>
                                <p>
                                    As a Toronto based Hubspot Certified Partner Agency, you can be rest assured that
                                    you will get the most personalized attention on every project we work on together. </p>
                                <p>&nbsp;</p>
                                <p>
                                    Being a small team ourselves we understand that not all businesses have big budgets.
                                    We work with you to pick the service package that’s right for you. Get in touch and let
                                    us help you. </p>
                                <p>&nbsp;</p>
                                <p>
                                    <h5>Our Promise:</h5> We will help you acquire, retain and grow your customer base.
                                </p>
                                {/*<a href="#0" className="butn bord curve mt-30">*/}
                                {/*    <span>More</span>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-lg-7 img">
                            <img src={nubelson} alt=""/>
                            <div className="stauts">
                                {/*<div className="item">*/}
                                {/*    <h4>*/}
                                {/*        number<span>K</span>*/}
                                {/*    </h4>*/}
                                {/*    <h6>Happy Clients</h6>*/}
                                {/*</div>*/}
                                {/*<div className="item">*/}
                                {/*    <h4>*/}
                                {/*        number<span>K</span>*/}
                                {/*    </h4>*/}
                                {/*    <h6>Successful Projects</h6>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
