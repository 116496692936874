import React, { Component } from "react";
import Biga from "../images/Biga.jpeg";
import Bigc from "../images/Bigab.jpeg";
import Bigb from "../images/bg.jpeg";
import Bigd from "../images/Bigad.jpeg";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alldata: [],
      items: "all",
    };
  }

  componentDidMount() {
    this.setState({
      data: [
        {
          img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
          title: "Fern",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
          title: "Snacks",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
          title: "Mushrooms",
          type: "web",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
          title: "Tower",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
          title: "Sea star",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
          title: "Honey",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
          title: "Basketball",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          title: "Breakfast",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
          title: "Tree",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          title: "Burger",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          title: "Camera",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
          title: "Coffee",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
          title: "Camping Car",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
          title: "Hats",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
          title: "Tomato basil",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
          title: "Mountain",
          type: "graphic",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
          title: "Bike",
          type: "graphic",
          info: "Ryan Hicks",
          desc: "Client Manager",
          desc: "Client Manager",
        },
      ],
      alldata: [
        {
          img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
          title: "Fern",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
          title: "Snacks",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
          title: "Mushrooms",
          type: "web",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
          title: "Tower",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
          title: "Sea star",
          type: "brand",
          info: "Exemple Title",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
          title: "Honey",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
          title: "Basketball",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          title: "Breakfast",
          type: "web",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
          title: "Tree",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          title: "Burger",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          title: "Camera",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
          title: "Coffee",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
          title: "Camping Car",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
          title: "Hats",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
          title: "Tomato basil",
          type: "brand",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
          title: "Mountain",
          type: "graphic",
          info: "Ryan Hicks",
          desc: "Client Manager",
        },
        {
          img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
          title: "Bike",
          type: "graphic",
          info: "Ryan Hicks",
          desc: "Client Manager",
          desc: "Client Manager",
        },
      ],
    });
  }
  render() {
    const { alldata, data, items } = this.state;
    const Label = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      border: "1px solid black",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }));

    const filter = (item) => {
      if (item === "all") {
        this.setState({ data: alldata, items: item });
      } else {
        this.setState({
          items: item,
          data: alldata.filter((c) => c.type === item),
        });
      }
    };
    return (
      <section class="portfolio-cr section-padding pb-50" id="portfolio">
        <div class="container">
          <div className="row">
            <div className="col-lg-10 col-md-10">
              <div className="sec-head">
                <p
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                  style={{ color: "#d1de40", fontSize: 48 }}
                >
                  PORTOFOLIO
                </p>
                <h3>
                  We create custom built software solutions for startups and
                  enterprises
                </h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="filtering text-center col-12">
              <div class="filter">
                <span
                  class={items === "all" ? "active" : " "}
                  onClick={() => filter("all")}
                >
                  All
                </span>
                <span
                  class={items === "brand" ? "active" : " "}
                  onClick={() => filter("brand")}
                >
                  Brandings
                </span>
                <span
                  class={items === "web" ? "active" : " "}
                  onClick={() => filter("web")}
                >
                  Mobile App
                </span>
                <span
                  class={items === "graphic" ? "active" : " "}
                  onClick={() => filter("graphic")}
                >
                  Creative
                </span>
              </div>
            </div>

            <div className="container" style={{ marginTop: 32 }}>
              <div className="team row lg-space">
                <Masonry columns={3} spacing={1}>
                  {data.map((item, index) => (
                    <Stack key={index}>
                      <div className="item cir md-mb50">
                        <div className="img">
                          <img
                            src={`${item.img}?w=162&auto=format`}
                            srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                            style={{
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius: 4,
                            }}
                          />

                          <div className="info">
                            <h6>{item.info}</h6>
                            <span style={{ color: "white" }}>{item.desc}</span>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  ))}
                </Masonry>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
