import React, { Component } from "react";
import nubelson from "../images/1000X1150.jpg";

export default class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemTile: "first",
      itemNumber: 1,
    };
  }
  render() {
    const { itemTile, itemNumber } = this.state;

    const filter = (itemTile, itemNumber) => {
      this.setState({ itemTile: itemTile, itemNumber: itemNumber });
    };
    return (
      <>
        <section className="blog-crv sub-bg" id="process">
          <div className="stories">
            <div className="container">
              <div
                className="row"
                style={
                  itemTile === "first"
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="col-lg-6  md-mb50">
                  <p
                    className="wow fadeIn"
                    data-wow-delay=".5s"
                    style={{ color: "#d1de40", fontSize: 48 }}
                  >
                    OUR PROCESS
                  </p>
                  <h3>Design & Architecture</h3>
                  <p>
                    Lorem ipsum dolor sit amet. consectetur adipiscings elit .
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.
                  </p>
                </div>
                <div className="col-lg-6 no-padding valign">
                  <div className="col-lg-12 img">
                    <img src={nubelson} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={
                  itemTile === "second"
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="col-lg-6  md-mb50">
                  <p
                    className="wow fadeIn"
                    data-wow-delay=".5s"
                    style={{ color: "#d1de40", fontSize: 48 }}
                  >
                    OUR PROCESS
                  </p>
                  <h3>Discovery & Strategy</h3>
                  <p>
                    Lorem ipsum dolor sit amet. consectetur adipiscings elit .
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.
                  </p>
                </div>
                <div className="col-lg-6 no-padding valign">
                  <div className="col-lg-12 img">
                    <img src={nubelson} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={
                  itemTile === "third"
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="col-lg-6  md-mb50">
                  <p
                    className="wow fadeIn"
                    data-wow-delay=".5s"
                    style={{ color: "#d1de40", fontSize: 48 }}
                  >
                    OUR PROCESS
                  </p>
                  <h3>Development & QA</h3>
                  <p>
                    Lorem ipsum dolor sit amet. consectetur adipiscings elit .
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.
                  </p>
                </div>
                <div className="col-lg-6 no-padding valign">
                  <div className="col-lg-12 img">
                    <img src={nubelson} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={
                  itemTile === "fourth"
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="col-lg-6  md-mb50">
                  <p
                    className="wow fadeIn"
                    style={{ color: "#d1de40", fontSize: 48 }}
                  >
                    OUR PROCESS
                  </p>
                  <h3>Final UAT + Launch</h3>
                  <p>
                    Lorem ipsum dolor sit amet. consectetur adipiscings elit .
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.
                  </p>
                </div>
                <div className="col-lg-6 no-padding valign">
                  <div className="col-lg-12 img">
                    <img src={nubelson} alt="" />
                  </div>
                </div>
              </div>

              <div className="controls">
                <div className="swiper-pagination swiper-pagination-fraction">
                  <span className="swiper-pagination-current">
                    {itemNumber}
                  </span>
                  <span className="swiper-pagination-total">4</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-us section-padding"
          style={{ backgroundColor: "#18191d", marginTop: -40 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 img">
                <div className="stauts deeeeee">
                  <div
                    onClick={() => filter("first", 1)}
                    className="item PROCESSDEV"
                    style={
                      itemTile === "first"
                        ? { backgroundColor: "#d1de40" }
                        : { backgroundColor: "white" }
                    }
                  >
                    <br />
                    <br />
                    <h6>Design & Architectures</h6>
                  </div>
                  <div
                    onClick={() => filter("second", 2)}
                    className="item PROCESSDEV"
                    style={
                      itemTile === "second"
                        ? { backgroundColor: "#d1de40" }
                        : { backgroundColor: "white" }
                    }
                  >
                    <br />
                    <br />
                    <h6 style={{ color: "black" }}>Discovery & Strategy</h6>
                  </div>

                  <div
                    onClick={() => filter("third", 3)}
                    className="item PROCESSDEV"
                    style={
                      itemTile === "third"
                        ? { backgroundColor: "#d1de40" }
                        : { backgroundColor: "white" }
                    }
                  >
                    <br />
                    <br />
                    <h6 style={{ color: "black" }}>Development & QA</h6>
                  </div>

                  <div
                    onClick={() => filter("fourth", 4)}
                    className="item PROCESSDEV"
                    style={
                      itemTile === "fourth"
                        ? { backgroundColor: "#d1de40" }
                        : { backgroundColor: "white" }
                    }
                  >
                    <br />
                    <br />
                    <h6 style={{ color: "black" }}>Final UAT + Launch</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
