import React, { Component } from "react";
import logo from "../images/logo.png";
import contact from "../images/contact.jpeg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HubspotContactForm from "./HubspotContactForm";

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      value: "1",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="logo" href="#">
            <img src={logo} alt="logo" style={{ width: 200 }} />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar">
              <i className="fas fa-bars"></i>
            </span>
          </button>


          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#hubspot">
                  Services
                </a>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" href="#portfolio">
                  Portfolio
                </a>
              </li> */}

              {/* <li className="nav-item">
                <a className="nav-link" href="#process">
                  Process
                </a>
              </li> */}

              {/* <li className="nav-item">
                <a className="nav-link" href="#team">
                  Team
                </a>
              </li> */}

              <li className="nav-item">
                <a className="nav-link" href="#blog">
                  Blog
                </a>
              </li>

              <li className="nav-item">
                <a
                  onClick={() => {
                    this.setState({ openDialog: true });
                  }}
                  href="#"
                  className="nav-link connect"
                  style={{
                    backgroundColor: "#d1de40",
                    color: "black",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginTop: 16,
                    borderRadius: 4,
                  }}
                >
                  Get in touch
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="tel:123-456-7890"
                  className="nav-link"
                  style={{
                    backgroundColor: "#d1de40",
                    color: "black",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginTop: 16,
                    borderRadius: 4,
                  }}
                >
                  647-699-5448
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Dialog
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openDialog}
          maxWidth="lg"
        >
          <DialogContent style={{ border: "8px #d1de40 solid" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <img src={contact} alt="logo" style={{ height: "100%" }} />
              </Grid>

              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        aria-label="lab API tabs example"
                        onChange={this.handleChange}
                      >
                        <Tab label="Questions" value="1" />
                        <Tab label="Information" value="2" />
                      </TabList>
                    </Box>

                    <TabPanel
                      value="1"
                      style={{
                        height: "75vh",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          marginTop: "-24px",
                          color: "black",
                          fontSize: 36,
                        }}
                      >
                        Talk to us about your next project.
                      </p>

                      <p style={{ color: "black", marginTop: "-8px" }}>
                      </p>
                        <HubspotContactForm />
                    </TabPanel>
                    <TabPanel value="2" style={{ height: "75vh" }}>
                      <footer
                        className="sub-bg"
                        style={{ backgroundColor: "white", color: "black" }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="item md-mb50">
                                <div className="title">
                                  <h5>Contact Us</h5>
                                </div>
                                <ul>
                                  <li>
                                    <span className="icon pe-7s-mail"></span>
                                    <div className="cont">
                                      <h6>Email Us</h6>
                                      <p>info@stackgenio.com</p>
                                    </div>
                                  </li>
                                  <li>
                                    <span className="icon pe-7s-call"></span>
                                    <div className="cont">
                                      <h6>Call Us</h6>
                                      <p>647-699-5448</p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </nav>
    );
  }
}
