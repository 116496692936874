import React, { Component } from "react";
import collins from "../images/1920X1080.jpg";
import bg2 from "../images/1920X10801.jpg";
import sticker from "../images/sticker.png";

export default class Slider extends Component {
  render() {
    return (
      <header className="slider slider-prlx fixed-slider text-center">
        <div className="swiper-container parallax-slider">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div
                  className="bg-img valign"
                  data-background={collins}
                  data-overlay-dark="6"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                      <div className="caption center mt-30">
                        <h1>Web App Development </h1>
                        <p>
                          <strong>
                            We design and develop custom modern website and
                            applications to meet your business needs.
                          </strong>
                        </p>
                        <a
                            href="#service"
                            className="butn bord curve mt-30"
                            style={{
                              backgroundColor: "#d1de40",
                              borderColor: "#d1de40",
                            }}
                        >
                          <span>
                            <strong>More</strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="bg-img valign"
                data-background={bg2}
                data-overlay-dark="6"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-9">
                      <div className="caption center mt-30">
                        <h1>Your Trusted HubSpot Partner</h1>
                        <p>
                          <strong>
                            We combine smart data-oriented strategies and an excellent technical expertise to grow revenue and inbound marketing ROI.</strong>
                        </p>
                        <a
                          href="#hubspot"
                          className="butn bord curve mt-30"
                          style={{
                            backgroundColor: "#d1de40",
                            borderColor: "#d1de40",
                          }}
                        >
                          <span>
                            <strong>More</strong>
                          </span>
                        </a>

                        <br />
                        <a
                          href="#certified"
                          className=""
                          style={{ marginTop: 32 }}
                        >
                          <img src={sticker} style={{ width: 140 }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="setone setwo">
            <div className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer">
              <i className="fas fa-chevron-right"></i>
            </div>
            <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer">
              <i className="fas fa-chevron-left"></i>
            </div>
          </div>
          <div className="swiper-pagination top botm "></div>

          <div className="social-icon">
            <a href="https://www.linkedin.com/company/stackgenio">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </header>
    );
  }
}
