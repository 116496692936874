import React, {Component} from "react";
import bg from "../images/1900X1050.jpeg";
import Bid from "../images/seamgen_logo.png";

export default class Video extends Component {
    render() {
        return (
            <section className="block-sec" data-scroll-index="4">
                <div
                    className="background bg-img pt-100 pb-0 parallaxie"
                    data-background={bg}
                    data-overlay-dark="5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* <div className="vid-area">
                  <span className="text">Watch Video</span>
                  <div className="vid-icon">
                    <a className="vid" href="https://vimeo.com/127203262">
                      <div className="vid-butn">
                        <span className="icon">
                          <i className="fas fa-play"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                </div> */}
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <div className="testim-box">
                                    <div className="head-box">
                                        <h6
                                            className="wow fadeIn"
                                            data-wow-delay=".5s"
                                            style={{color: "#d1de40"}}
                                        >
                                            Our Clients
                                        </h6>
                                        <h4 className="wow fadeInLeft" data-wow-delay=".5s">
                                            What our clients are saying
                                        </h4>
                                    </div>
                                    <div className="slic-item wow fadeInUp" data-wow-delay=".5s">
                                        <div className="item">
                                            <p>
                                                Stackgenio helped us deliver two critical and complex web and mobile
                                                projects. I highly recommend their service.
                                            </p>
                                            <div className="info">
                                                <div className="img">
                                                    <div className="img-box">
                                                        {/*<img src={Bid} alt="im"/>*/}
                                                    </div>
                                                </div>
                                                <div className="cont">
                                                    <div className="author">
                                                        <h6 className="author-name ">Shaheen Georgee, Software
                                                            Engineering Manager</h6>
                                                        <span className="author-details">Seamgen</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
