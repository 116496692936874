import React, { Component } from "react";
import ik from "../images/1000X560.jpeg";

export default class Footer extends Component {
  render() {
    return (
      <footer className="sub-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="item md-mb50">
                <div className="title">
                  <h5>Contact Us</h5>
                </div>
                <ul>
                  <li>
                    <span className="icon pe-7s-call"></span>
                    <div className="cont">
                      <h6>Call Us</h6>
                      <p>647-699-5448</p>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-mail"></span>
                    <div className="cont">
                      <h6>Email Us</h6>
                      <p>info@stackgenio.com</p>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-map-marker"></span>
                    <div className="cont">
                      <h6>Office</h6>
                      <p>Greater Toronto Area, Canada (Remote)</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item md-mb50">
                {/*<div className="title">*/}
                {/*  <h5>Recent News</h5>*/}
                {/*</div>*/}
                <ul>
                  <li>
                    {/*<div className="img">*/}
                    {/*  <img src={ik} alt="" />*/}
                    {/*</div>*/}
                    {/*<div className="sm-post">*/}
                    {/*  <p>*/}
                    {/*    The Start-Up Ultimate Guide to Make Your WordPress*/}
                    {/*    Journal.*/}
                    {/*  </p>*/}
                    {/*  <span*/}
                    {/*    className="date"*/}
                    {/*    style={{*/}
                    {/*      color: "#d1de40",*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    14 sep 2021*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                  </li>
                  <li>
                    {/*<div className="subscribe">*/}
                    {/*  <input type="text" placeholder="Type Your Email" />*/}
                    {/*  <span*/}
                    {/*    className="subs pe-7s-paper-plane"*/}
                    {/*    style={{ backgroundColor: "#d1de40" }}*/}
                    {/*  ></span>*/}
                    {/*</div>*/}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <div className="logo">
                  <img src="img/logo-light.png" alt="" />
                </div>
                <div className="social">
                  <a href="https://www.linkedin.com/company/stackgenio">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
                <div className="copy-right">
                  <p>© 2022. Stackgenio, a Dockiment Corp. company</p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
