import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import blogimage from "../images/1000X560.jpeg";

let aricle = { cover: null };

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      aricle: [],
    };
  }

  render() {
    const { aricle } = this.state;
    const data = [
      {
        id: 1,
        day: "05",
        month: "May 2017",
        author: "by / Nouhoun Diarra",
        category: "Spring Boot/Angular",
        title: "Secure a Spring Boot REST API with JSON Web Token",
        cover: blogimage,
        description:
          "Secure a Spring Boot REST API with JSON Web Token",
      },
    ];

    const show = (id) => {
      const article = data.filter((c) => c.id === id)[0];
      this.setState({ aricle: article, openDialog: true });
    };
    return (
      <section
        className="blog-grid section-padding position-re"
        style={{ backgroundColor: "#d1de40" }}
        id="blog"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
              <div className="sec-head">
                <p
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                  style={{ color: "gray", fontSize: 48 }}
                >
                  BLOG
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((item, index) => (
              <div
                className="col-lg-4 wow fadeInUp"
                data-wow-delay={"." + item.id + "s"}
              >
                <div
                  className="item active bg-img"
                  data-background={item.cover}
                >
                  <div className="cont">
                    <a href="#0" className="date" onClick={() => show(item.id)}>
                      <span>
                        <i>{item.day}</i> {item.month}
                      </span>
                    </a>
                    <div className="info">
                      <a href="#0" className="author">
                        <span>{item.author}</span>
                      </a>
                      <a href="#0" className="tag">
                        <span style={{ color: "#d1de40" }}>
                          {item.category}
                        </span>
                      </a>
                    </div>
                    <h6>
                      <a href="#0">{item.title}</a>
                    </h6>
                    <div className="btn-more">
                      <a href="https://medium.com/@nydiarra/secure-a-spring-boot-rest-api-with-json-web-token-reference-to-angular-integration-e57a25806c50" target="_blank" className="simple-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="line top right"></div>
        <div className="line bottom left"></div>

        <Dialog
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openDialog}
          maxWidth="lg"
        >
          <DialogContent style={{ border: "8px #d1de40 solid" }}>
            <Grid container spacing={4} style={{ padding: 8 }}>
              <Grid item xs={6}>
                <img src={aricle.cover} alt="logo" />
              </Grid>
              <Grid item xs={6}>
                <h3>{aricle.title}</h3>
                <p style={{ paddingTop: 8 }}>
                  posted: {aricle.day} {aricle.month}
                </p>
                <p>{aricle.description}</p>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </section>
    );
  }
}
