import React, { Component } from "react";
import Wx from "../images/570X700.jpeg";

export default class Team extends Component {
  render() {
    return (
      <section
        className="team section-padding"
        style={{ backgroundColor: "#d1de40", zIndex: 0 }}
        id="team"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
              <div className="sec-head ">
                <h6
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                  style={{ color: "black" }}
                >
                  Our Team
                </h6>
                <h3 style={{ color: "black" }}>This is our genious members</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item cir md-mb50">
                <div className="img">
                  <img src={Wx} alt="" />

                  <div className="info">
                    <h6>Ryan Hicks</h6>
                    <span style={{ color: "white" }}>Client Manager</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item cir md-mb50">
                <div className="img">
                  <img src={Wx} alt="" />

                  <div className="info">
                    <h6>Ryan Hicks</h6>
                    <span style={{ color: "white" }}>Client Manager</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item cir sm-mb50">
                <div className="img">
                  <img src={Wx} alt="" />

                  <div className="info">
                    <h6>Ryan Hicks</h6>
                    <span style={{ color: "white" }}>Client Manager</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item cir">
                <div className="img">
                  <img src={Wx} alt="" />

                  <div className="info">
                    <h6>Ryan Hicks</h6>
                    <span style={{ color: "white" }}>Client Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
