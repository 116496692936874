import React, { Component } from "react";
import contact from "../images/contact.jpeg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";

export default class Action extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      value: "1",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <section
        className="call-action section-padding sub-bg bg-img"
        data-background="img/patrn.svg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-6">
              <div className="content sm-mb30">
                <h6 className="wow" data-splitting style={{ color: "#d1de40" }}>
                  Let’s Talk
                </h6>

                <h2 className="wow" data-splitting>
                  about your{" "}
                </h2>

                <span
                  className="butn"
                  data-wow-delay=".5s"
                  style={{
                    backgroundColor: "#d1de40",
                    color: "black",
                    fontSize: 48,
                    fontWeight: "bold",
                  }}
                >
                  NEXT PROJECT
                </span>
              </div>
            </div>

            <div className="col-md-3 col-lg-6 " style={{ textAlign: "right" }}>
              <a
                onClick={() => {
                  this.setState({ openDialog: true });
                }}
                href="#"
                className="butn bord curve wow fadeInUp"
                data-wow-delay=".5s"
                style={{
                  backgroundColor: "#d1de40",
                  color: "black",
                  marginTop: 140,
                  fontSize: 18,
                }}
              >
                <strong>Get in touch</strong>
              </a>
            </div>
          </div>
        </div>

        <Dialog
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openDialog}
          maxWidth="lg"
        >
          <DialogContent style={{ border: "8px #d1de40 solid" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <img src={contact} alt="logo" style={{ height: "100%" }} />
              </Grid>

              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        aria-label="lab API tabs example"
                        onChange={this.handleChange}
                      >
                        <Tab label="Questions" value="1" />
                        <Tab label="Information" value="2" />
                      </TabList>
                    </Box>

                    <TabPanel
                      value="1"
                      style={{
                        height: "75vh",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          marginTop: "-24px",
                          color: "black",
                          fontSize: 36,
                        }}
                      >
                        Get in Touch
                      </p>

                      <p style={{ color: "black", marginTop: "-8px" }}>
                        Any questions or remarks ? <br />
                        We'll get back you within 24 hours
                      </p>

                      <TextField
                        id="outlined-basic"
                        label="Name"
                        placeholder="Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon
                                style={{ color: "#d1de40" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        style={{
                          backgroundColor: "#eef1f6",
                          width: "100%",
                          marginTop: 32,
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        placeholder="Mail adress"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailOutlinedIcon style={{ color: "#d1de40" }} />
                            </InputAdornment>
                          ),
                        }}
                        style={{
                          backgroundColor: "#eef1f6",
                          width: "100%",
                          marginTop: 24,
                        }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Company"
                        variant="outlined"
                        placeholder="Company"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ApartmentIcon style={{ color: "#d1de40" }} />
                            </InputAdornment>
                          ),
                        }}
                        style={{
                          backgroundColor: "#eef1f6",
                          width: "100%",
                          marginTop: 16,
                        }}
                      />

                      <TextField
                        id="outlined-multiline-static"
                        label="Enter your message"
                        multiline
                        rows={4}
                        placeholder="Enter your message"
                        style={{
                          backgroundColor: "#eef1f6",
                          width: "100%",
                          marginTop: 16,
                        }}
                      />

                      <a
                        href="#0"
                        className="butn bord curve mt-30"
                        style={{
                          backgroundColor: "#d1de40",
                          borderColor: "#d1de40",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontSize: 24 }}>
                          <strong>Send message</strong>
                        </span>
                      </a>
                    </TabPanel>
                    <TabPanel value="2" style={{ height: "75vh" }}>
                      <footer
                        className="sub-bg"
                        style={{ backgroundColor: "white", color: "black" }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="item md-mb50">
                                <div className="title">
                                  <h5>Contact Us</h5>
                                </div>
                                <ul>
                                  <li>
                                    <span className="icon pe-7s-map-marker"></span>
                                    <div className="cont">
                                      <h6>Officeal Address</h6>
                                      <p>Toronto-Canada</p>
                                    </div>
                                  </li>
                                  <li>
                                    <span className="icon pe-7s-mail"></span>
                                    <div className="cont">
                                      <h6>Email Us</h6>
                                      <p>info@stackgenio.technology</p>
                                    </div>
                                  </li>
                                  <li>
                                    <span className="icon pe-7s-call"></span>
                                    <div className="cont">
                                      <h6>Call Us</h6>
                                      <p>+87986451666</p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </section>
    );
  }
}
