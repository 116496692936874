import React, { Component } from "react";
import nubelson from "../images/nubelson.jpg";
import sticker from "../images/sticker.png";

export default class Certified extends Component {
  render() {
    return (
      <section
        className="about-us section-padding"
        style={{ backgroundColor: "white" }}
        id="certified"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 valign md-mb50">
              <img src={nubelson} alt="" style={{ height: "100%" }} />
            </div>

            <div className="col-lg-8">
              <div className="mb-50">
                <h6
                  className="fw-100 text-u ls10 mb-10"
                  style={{ color: "black" }}
                >
                  HubSpot CERTIFICATION
                </h6>
                <h3 style={{ color: "black" }}>
                  We're a HubSpot Certified Small Business
                </h3>

                <div className="row" style={{ color: "black", marginTop: 32 }}>
                  <div className="col-md-6">
                    <img src={sticker} style={{ width: 200 }} />
                  </div>
                </div>

                <div className="row" style={{ color: "black", marginTop: 128 }}>
                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>541 511</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Customer Computer Programming Services
                    </p>
                  </div>

                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>511 210</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Software Publlishers
                    </p>
                  </div>

                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>541 512</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Customer System Design Services
                    </p>
                  </div>
                </div>

                <div className="row" style={{ color: "black", marginTop: 32 }}>
                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>541 513</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Computer Facolities Management Services
                    </p>
                  </div>

                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>541 519</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Other Computer related Services
                    </p>
                  </div>

                  <div
                    className="col-md-4 col-6 brands"
                    style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 24,
                      }}
                    >
                      <strong>541 519</strong>
                    </p>

                    <p
                      style={{
                        color: "gray",
                        fontSize: 16,
                      }}
                    >
                      Other Computer related Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
