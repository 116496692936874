import React, { Component } from "react";

export default class Number extends Component {
  render() {
    return (
      <section
        class="number-sec section-padding"
        style={{ backgroundColor: "white" }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-10">
              <div class="sec-head  text-center">
                <h3 style={{ color: "black" }}>
                  Pleasure in the job puts perfection in the work.
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb50">
                <span
                  className="icon pe-7s-smile"
                  style={{
                    color: "#d1de40",
                  }}
                ></span>
                <h3 className="custom-font">
                  <span
                    className="count"
                    style={{
                      color: "black",
                    }}
                  >
                    2400
                  </span>
                </h3>
                <p
                  className="wow txt"
                  data-splitting
                  style={{
                    color: "black",
                  }}
                >
                  <strong>Happy Clients</strong>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb50">
                <span
                  className="icon pe-7s-portfolio"
                  style={{
                    color: "#d1de40",
                  }}
                ></span>
                <h3 className="custom-font">
                  <span
                    className="count"
                    style={{
                      color: "black",
                    }}
                  >
                    133
                  </span>
                </h3>
                <p
                  className="wow txt"
                  data-splitting
                  style={{
                    color: "black",
                  }}
                >
                  <strong>Compleate Projects</strong>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item sm-mb50">
                <span
                  className="icon pe-7s-cloud-download"
                  style={{
                    color: "#d1de40",
                  }}
                ></span>
                <h3 className="custom-font">
                  <span
                    className="count"
                    style={{
                      color: "black",
                    }}
                  >
                    254
                  </span>
                  k
                </h3>
                <p
                  className="wow txt"
                  data-splitting
                  style={{
                    color: "black",
                  }}
                >
                  <strong>Files Downloaded</strong>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <span
                  className="icon pe-7s-medal"
                  style={{
                    color: "#d1de40",
                  }}
                ></span>
                <h3 className="custom-font">
                  <span
                    className="count"
                    style={{
                      color: "black",
                    }}
                  >
                    46
                  </span>
                </h3>
                <p
                  className="wow txt"
                  data-splitting
                  style={{
                    color: "black",
                  }}
                >
                  <strong>Award Win</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
