import React, { Component } from "react";

export default class Brand extends Component {
  render() {
    return (
      <section className="skills-circle pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="item wow fadeInLeft" data-wow-delay=".6">
                      <div className="skill" data-value={0.9}>
                        <span className="">90%</span>
                      </div>
                      <div className="cont">
                        <span>Project</span>
                        <h6>Consulting</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="item wow fadeInLeft" data-wow-delay=".3">
                      <div className="skill" data-value={0.75}>
                        <span className="">75%</span>
                      </div>
                      <div className="cont">
                        <span>App</span>
                        <h6>Development</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
