import React, { Component } from "react";

import sticker from "../images/sticker.png";

export default class Client extends Component {
  render() {
    return (
      <section
        className="clients section-padding"
        style={{ paddingTop: 200, backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 valign md-mb50">
              <div className="sec-head mb-0">
                <h3 className="wow mb-20 " style={{ color: "black" }}>
                  Our Partners
                </h3>
              </div>
            </div>
            <div className="col-lg-8">
              <div>
                <div className="row bord">
                  <div className="col-md-3 col-6 brands">
                    <div className="item wow fadeIn" data-wow-delay=".3s">
                      <div className="img">
                        <img
                          src={sticker}
                          alt=""
                          style={{ width: "400px !important" }}
                        />
                        <a
                          href="https://www.hubspot.com/"
                          target="_blank"
                          className="link"
                          data-splitting
                          style={{ color: "black" }}
                        >
                          HubSpot
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
