import React, { Component } from "react";
import java from "../images/java.png";
import js from "../images/Js.png";
import ts from "../images/TS.png";
import python from "../images/python.png";
import HTML from "../images/HTML.png";
import CSS from "../images/CSS.png";
import react from "../images/react.png";
import angular from "../images/A.png";
import NodeJS from "../images/NodeJS.png";
import JHipster from "../images/JHipster.png";
import Hubspot from "../images/Hubspot.png";
import MYSQL from "../images/MYSQL.png";
import elephant from "../images/elephant.png";
import oracle from "../images/oracle.png";
import AWS from "../images/AWS.png";
import Kubernetes from "../images/Kubernetes.png";
import docker from "../images/docker.png";
import springboot from "../images/springboot.png";
import springFramework from "../images/springFramework.png";
// import Wordpress from "../images/Wp.png";


export default class Technology extends Component {
  render() {
    return (
      <section
        className="feat sub-bg section-padding"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head">
                <p
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                  style={{ color: "gray", fontSize: 48 }}
                >
                  TECHNOLOGY STACK
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-64px" }}>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={java}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={js}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={ts}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={python}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 32 }}>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={HTML}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={CSS}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={react}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={angular}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 32 }}>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={NodeJS}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={JHipster}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={Hubspot}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={MYSQL}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 32 }}>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={elephant}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={oracle}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={AWS}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6 "
              style={{ boxShadow: "1px 3px 1px #f1f1f1" }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={Kubernetes}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 32 }}>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={docker}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={springboot}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>

            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              <div className="img">
                <img
                  className="mx-auto d-block"
                  src={springFramework}
                  alt=""
                  style={{ width: 100, padding: "16px 0" }}
                />
              </div>
            </div>
            <div
              className="col-md-3 col-6"
              style={{
                boxShadow: "1px 3px 1px #f1f1f1",
              }}
            >
              {/*<div className="img">*/}
              {/*  <img*/}
              {/*    className="mx-auto d-block"*/}
              {/*    src={Wordpress}*/}
              {/*    alt=""*/}
              {/*    style={{ width: 100, padding: "16px 0" }}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
