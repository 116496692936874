import React, {Component} from "react";
import sticker from "../images/sticker.png";

export default class Services extends Component {
    render() {
        return (
            <>
                <section className="feat sub-bg section-padding" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10">
                                <div className="sec-head">
                                    <h3 data-wow-delay=".5s" style={{fontSize: 48}}>
                                        Web App Development
                                    </h3>
                                    <p
                                        className="wow fadeIn"
                                        data-wow-delay=".5s"
                                        style={{width: 1200}}
                                    >
                                        <p>
                                            We provide an end-to-end solution to host your website in the cloud,
                                            monitor,
                                            and implement all the required integrations and design and develop custom
                                            websites and web apps for your business. We look after optimization and
                                            technical trouble shooting so you don’t have to worry about anything.
                                        </p>

                                        <p>
                                            The Stackgenio team is your partner for all things Web App Development and
                                            HubSpot.
                                        </p>

                                        <p>
                                            The services we offer are outlined below. Contact us today for your custom
                                            quote
                                            depending on the project specifications. A Stackgenio account manager will
                                            be
                                            your point person to address any questions you may have.
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 items active md-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-ios-monitor"></i>
                  </span>
                                    <h5>Development</h5>
                                    <p className="textsevice">
                                        Security is our number one priority when we develop custom web applications from
                                        scratch with the latest technologies.
                                        With our agile process, we help you iteratively create applications that add
                                        value to your business without breaking the bank.
                                        Take advantage of powerful content management systems such WordPress and HubSpot
                                        CMS to speed up your application development and the flexibility to be able to
                                        maintain it yourself.
                                        We can help set up, implement, and provide ongoing training for WordPress and
                                        HubSpot CMS.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items  md-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-ios-bolt-outline"></i>
                  </span>
                                    <h5>Integration</h5>
                                    <p className="textsevice">
                                        Integrate your existing application with any publicly known or private
                                        application to enrich it.
                                        We can integrate your existing application with Shopify, HubSpot, Google Maps or
                                        any application based on standard industry integration protocols and patterns.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items sm-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-cube"></i>
                  </span>
                                    <h5>Hosting</h5>
                                    <p className="textsevice">
                                        We provide cloud-based hosting services and deployment for your application or
                                        website on Amazon AWS. Each application is unique and we work with you on your
                                        custom project.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 items">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-ios-color-wand"></i>
                  </span>
                                    <h5>Search Engine Optimization</h5>
                                    <p className="textsevice">
                                        Looking to amplify your online presence? Increase website traffic? Improve brand
                                        awareness and engagement?
                                        We use the latest search engine optimization tools and techniques to boost your
                                        google rankings
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    id="hubspot"
                    className="feat sub-bg section-padding"
                    style={{backgroundColor: "white"}}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10">
                                <div className="sec-head">
                                    <p
                                        style={{width: 1200}}
                                        className="wow fadeIn"
                                        data-wow-delay=".5s"
                                    >
                                        The Stackgenio team provides Web App Development and HubSpot solutions for your
                                        team so you can focus on what matters most - building long lasting customer
                                        relationships and growing revenue.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-10">
                                <div className="sec-head">
                                    <h3
                                        data-wow-delay=".5s"
                                        style={{color: "black", fontSize: 48}}
                                    >
                                        HubSpot
                                    </h3>
                                    <p
                                        style={{width: 1200}}
                                        className="wow fadeIn"
                                        data-wow-delay=".5s"
                                    >
                                        <img
                                            src={sticker}
                                            style={{width: 100, float: "left", marginRight: 16}}
                                        />
                                        We are a Toronto based HubSpot Certified Partner Agency that can help you with
                                        Sales Acceleration, HubSpot CRM set up, content scheduling, or ongoing
                                        optimization and technical HubSpot support.

                                        We have 3 buckets of HubSpot services that you can choose from depending on your
                                        organizational needs
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 items active md-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-ios-monitor"></i>
                  </span>

                                    <h5 className="textsevicesss">
                                        Starter
                                    </h5>

                                    <span style={{fontSize: 16}} className="textoooo">
                    <strong>Starts at $4,000 one-time</strong>
                  </span>
                                    <p className="textsevice">
                                        A lightweight, low-cost and low-risk approach to get started with HubSpot.
                                        <ul style={{paddingTop: 16}}>
                                            <li>CRM Setup</li>
                                            <li>HubSpot Sales Installation</li>
                                            <li>Data Import & Cleansing</li>
                                            <li>Setup custom properties</li>
                                            <li>Custom Fields and View Creation</li>
                                            <li>One Custom Landing Page</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 items  md-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-ios-bolt-outline"></i>
                  </span>
                                    <h5 className="textsevicesss">
                                        PRO
                                    </h5>

                                    <span style={{fontSize: 16}} className="textoooo">
                    <strong>Starts at $4,000 per month</strong>
                  </span>
                                    <p className="textsevice">
                                    A package to take advantage of the entire HubSpot suite with solid support
                                    from us.
                                        <ul style={{paddingTop: 16}}>
                                            <li>CRM, Sales, Marketing Setup</li>
                                            <li>Reporting Dashboard Setup (Company Level)</li>
                                            <li>CRM Deal Stage Evaluation and Exit Criteria</li>
                                            <li>Sales Acceleration Tech Setup</li>
                                            <li>Custom Fields and View Creation</li>
                                            <li>Data Import & Cleansing</li>
                                            <li>Basic Website with HubSpot CMS</li>
                                            <li>Up 3 Custom Landing Pages</li>
                                            <li>Email Template Creation and Optimization</li>
                                            <li>Technical Support</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 items sm-mb30">
                                <div className="item wow fadeIn" data-wow-delay=".3s">
                  <span className="icon">
                    <i className="ion-cube"></i>
                  </span>
                                    <h5 className="textsevicesss">Integration</h5>

                                    <span style={{fontSize: 16}} className="textoooo">
                  </span>
                                    <p className="textsevice">
                                        <ul style={{paddingTop: 16}}>
                                            <li>
                                                We can help you integrate HubSpot with variety publicly known
                                                applications and API.
                                                Each integration project is unique. Please get in touch today to see how
                                                we can help.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        );
    }
}
