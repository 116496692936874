import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import About from "../components/About";
import Number from "../components/Number";
import Services from "../components/Services";
import Certified from "../components/Certified";
import Portfolio from "../components/Portfolio";
import Video from "../components/Video";
import Brand from "../components/Brand";
import Team from "../components/Team";
import Client from "../components/Client";
import Process from "../components/Process";
import Technology from "../components/Technology";
import Blog from "../components/Blog";
import Action from "../components/Action";
import Footer from "../components/Footer";

export default class Home extends Component {
  render() {
    return (
      <>
        <Navbar />

        <Slider />

        <div className="main-content">
          <Services />

          <About />

          {/* <Number /> */}

          {/* <Certified /> */}

          {/* <Portfolio /> */}

          <Video />

          {/* <Brand /> */}

          {/* <Team /> */}

          <Client />

          {/* <Process /> */}

          <Technology />

          <Blog />

          <Action />

          <Footer />
        </div>
      </>
    );
  }
}
